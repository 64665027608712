@import "~normalize.css/normalize.css";
@import "variables.scss";
@import "grid.scss";
@import "colors.scss";
@import "spacing.scss";
@import "visibility.scss";
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Splash&display=swap');
body {

}
html {
  box-sizing: border-box;
  font-family: $primaryFont;
  font-weight: 400;
  font-size: 20px;
  background-color: $primaryColor;
}
*, *:before, *:after {
  box-sizing: inherit;
}
h1,h2,h3,h4,h5,h6{
   font-family: $secondaryFont;
}
p{line-height: 1.75;}
.fancy-font{
  font-family: "Splash", cursive;
  font-weight: 400;
  font-style: normal;
}
html,
body,
#root,
.wrapper {
  display: block;
  -webkit-font-smoothing: antialiased;
	min-height: 100%;
	height: 100%;
}
.wrapper {
  height: 100%;
  background: $lightGrey;
	height  : 100%;
  overflow: hidden;
}
.back-button{
  height: 45px;
  left: 20px;
  position: absolute;
  top: 80px;
  width: 45px;
  z-index: 5;
  background-color: rgba(0,0,0,.1);
  border-radius: 50%;
}
main {
    position: relative;
}

.animated-page-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
	  -webkit-overflow-scrolling:touch;
	  overflow-x: hidden;
}
.button{
    background-color: #fff;
    border: 1px solid #000;
    color: #000;
    display: inline-block;
    font-weight: bold;
    margin-top: 20px;
    padding: 40px;
    padding-bottom: 20px;
    padding-top: 20px;
    text-decoration: none;
    transition: all .25s ease-out;
}
.vertical-tag{
  writing-mode: vertical-lr;
  position: absolute;
  height: 500px;
  letter-spacing: 10;
  color: #fff;
  text-transform: uppercase;
  z-index: 100;
  top:0;
}
.vertical-tag.right{
  right: 20px;
}
.vertical-tag.left{
  left: 20px;
}
.lip{
  margin-top: -5vh;
  position: relative;
  z-index: 100;
}
.subheading{
  opacity: .65;
}
.alternating-grid-text h2{
  font-size: 30px;
  font-weight: bold;
  transform: translateY(-50%) scale(1.75,1.75) translateX(20%);
}
h1.oversized-text{
  transform: translateY(-50%) scale(1.75,1.75);
}
.alternating-grid-row{
  background-size: cover;
  background-position: center;
}
.alternating-grid-row.reverse{
  flex-direction: row-reverse;
}
.alternating-grid-image{
  width: 100%;
  background-size: cover;
  background-position: center;
}
.alternating-grid-text{
  padding:10%;
}
.button:hover {
    background-color: #000;
    color: #fff;
}
.page {
    height: 100%;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
}
/* Standards */
.shadow-light {
    webkit-box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.05);
    moz-box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.05);
    ms-box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.05);
    box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.05);
}
.circle{
  border-radius: 50%;
}
/*text */
.centered-text{
  text-align: center;
}
.subheading{
	opacity:.75;
}
.uppercase{
	text-transform:uppercase;
}
.clip-text {
    font-weight: bold;
    line-height: 1;
    position: relative;
    display: inline-block;
    color: #fff;
    -webkit-background-clip: text;
    -webkit-text-fill-color: rgba(255,255,255,0);
	  background-size: cover;
}
.clip-text:after {
    position: absolute;
    z-index: -1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

nav{
  width: 75%;
  float: right;
  padding-top: 10px;
}
.hover-grow{
	transform:scale(1,1);
	box-shadow: 0px 0px 20px rgba(0,0,0,0);
	transition: all .25s ease-in-out;
}
.hover-grow:hover{
	box-shadow: 0px 0px 20px rgba(0,0,0,.05);
	transform:scale(1.025,1.025);
}
#mobile-menu-icon{
	float:right;
	height: 30px;
	width:30px;
	margin-top: 0px;
  margin-right: 10px;
}
#mobile-menu{
	z-index: -1;
	position: fixed;
  left:-100vw;
}
.slant-bottom{
	 clip-path: polygon(0 0, 100% 0, 100% calc(100% - 10%), 0 100%);
   -webkit-clip-path: polygon(0 0, 100% 0, 100% calc(100% - 10%), 0 100%);
   margin-bottom: -10%;
}
.slant-top{
	 clip-path: polygon(0 0, 100% 0, 100% calc(100% - 10%), 0 100%);
   -webkit-clip-path: polygon(0 0, 100% 0, 100% calc(100% - 10%), 0 100%);
   //margin-bottom: -10%;
}
.card{
  box-shadow: 0px 0px 40px rgba(0,0,0,.15)
}
.shadow{
  box-shadow: 0px 0px 40px rgba(0,0,0,.15)
}
.button{
  display: inline-block;
  padding: 20px;
  padding-left: 50px;
  padding-right: 50px;
  font-weight: bold;
  border: 0px solid;
  border-radius: 8px;
}
.Collapsible {
  padding-left:2vh;
  padding-right:2vh;
  border-bottom: 2px solid $primaryColor;
}
.Collapsible .Collapsible__trigger{
  text-decoration: none;
  font-weight: bold;
  padding-top: 2vh;
  padding-bottom: 2vh;

  cursor: pointer;
  display: block;
}

.testimonial-card{
  background-color:$primaryDarkColor;
  text-align: left;
  height: 100%;
  border-radius: $globalRadius;
  box-shadow: 0 30px 40px rgba(0,0,0,.15)
}
@media screen{
}

@media only screen and (max-width: 800px) {
  .story-lip.lip {
    margin-top: 0px;
  }
  .hero.full{
    height: 70vh !important;
  }
  .alternating-grid-text h2{
    font-size: 30px;
    font-weight: bold;
    transform: translateY(0%) scale(1,1) translateX(0%);
  }
  h1.oversized-text{
    font-size: 40px;
    font-weight: bold;
    transform: translateY(0%) scale(1,1) translateX(0%);
  }
  .lip{
    margin-top: -15vh;
  }
  .gutter{
    padding-left: 10px;
    padding-right: 10px;
  }
  .mobile-reverse {
		flex-direction: column-reverse;
	}
    .small-centered-text {
        text-align: center;
    }
    .small-right-text {
        text-align: right;
    }
    .small-left-text {
        text-align: right;
    }
	#mobile-menu{
		opacity:0;
		z-index: -1;
		height: 100%;
		width: 100%;
		transform: scale(1.25,1.25);
		transition: transform .45s ease-in-out;
		text-align: center;
		display: flex;
		background-size: cover;
		.logo-light{
			width:100%;
			max-width: 100px;
		}
		.mobile-menu-content{
			margin:auto;
			width: 100%;
			height:50%;
		}
		a {
			color: #fff;
			font-size: 20px;
			height: 25%;
			text-decoration: none;
			width: 100%;
			display: block;
		}

	}
	#mobile-menu.active{
		opacity:1;
    left:0;
		z-index: 1001;
		transform: scale(1,1);
	}
  .vertical-tag{
    display: none !important;
  }
  .home-page-slant-shim .side-by-side-card-and-image-container.slant-bottom{
    padding-top: 5vh;
  }
}

@media screen and (min-width: 800px) and (max-width: 1280px) {

}
@media screen and (max-width: 1280px){
  .vertical-tag{
    top:-100px;
  }
}
@media screen and (max-width: 800px) {
  .button{
    padding:-20px;
    font-size: 16px;
  }
}
@media all and (-ms-high-contrast:none){
  .background-grow-hover:hover .background-grow-hover-back{
    opacity: .5 !important;
  }

  .clip-text {
      background-image:none !important;
      color: #fff;
  }
}

@-moz-document url-prefix() {
  .clip-text {
    background-image:none !important;
    color: #fff;
    -webkit-background-clip:unset;
    -webkit-text-fill-color:rgba(255,255,255,.8);
  }
}
