@import "variables.scss";
.primary-color{
    color: $primaryColor;
}
.primary-background{
    background-color: $primaryColor;
}
.secondary-color{
    color: $secondaryColor;
}
.secondary-background{
    background-color: $secondaryColor;
}
.white{
    color:$white;
}
.white-background{
    background-color: $white;
}
.black{
    color:$black;
}
.black-background{
    background-color: $black;
}

.light-grey{
    color:$lightGrey;
}
.light-grey-background{
    background-color: $lightGrey;
}
.medium-grey-color{
    color: $mediumGrey;
}
.medium-grey-background{
    background-color: $mediumGrey;
}
.dark-grey-color{
    color: $darkGrey;
}
.dark-grey-background{
    background-color: $darkGrey;
}
.green-color{
    color: $green;
}
.green-background{
    background-color: $green;
}
.red-color{
    color: $red;
}
.red-background{
    background-color: $red;
}
