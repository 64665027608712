@import "./../../styles/variables.scss";

#header.top-bar{
  background-color: $primaryColor;
  .header-cta{
    background-color: $tertiaryColor;
    color:$white;
    padding:10px;
    padding-left: 35px;
    padding-right: 35px;
    margin-top: 0px;
    border-radius: 4px;
  }
}
#header nav{
  padding-top: 12px;
  padding-bottom: 10px;
}
/* Header */
.logo-container{
  width:25%;
  float: left;
  height: 100%;
  text-align:left;
  position: absolute;
}
.logo-container .logo{
  width:100%;
  max-width: 130px;
  margin-top: -10px;
  margin-left: 20px;
}
.top-bar .logo-container a {
  opacity: 1 !important;
}
.top-bar-background{
    height: 59px;
}
.top-bar {

    text-align: right;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 10;
    background-color: rgba(255,255,255,0);
    -webkit-transition: all .25s ease-out;
    transition: all .25s ease-out;
	box-shadow: 0px 0px 80px rgba(0,0,0,0);
    a {
        margin-right: 1.5rem;
		font-weight: 500;
        color: $white;
        text-decoration: none;
        opacity: .75;
		text-transform: uppercase;
		font-size: 14px;
        &.active {
            font-weight: bold;
            opacity: 1;
        }
    }
    .logo-container a{
      margin:0;
      margin-right: 0;
    }
    .logo-container .logo-light {
        display: block;
    }
    .logo-container .logo-dark {
        display: none;
    }
	.mobile-menu-icon-dark {
      display: none;
	}
	.mobile-menu-icon-light {
	  display: block;
	}
  .coupon-container{
    text-align: center;
    color:#fff;
    padding:10px;
    background-color: $primaryDarkColor;
  }
}

#mobile-menu{
  background-size: cover;
  background-position: center;
  background-color: $primaryDarkColor;
  .header-cta{
    background-color: $tertiaryColor;
    color:$white;

  }
}
#mobile-menu .logo-light{
  position: absolute;
  top: 0;
  width: 200px;
  max-width: none;
  margin-left: -100px
}
.top-bar.active{
  background: $white;
  padding: 15px;
  font-weight: bold;
  box-shadow: 0px 0px 80px rgba(0,0,0,.1);
  a {
      color: $black;
  }
  .logo-container .logo {
    max-width: 90px;
  }
  .logo-container .logo-dark {
      display: block;
  }
  .logo-container .logo-light {
      display: none;
  }
  .mobile-menu-icon-dark {
      display: block;
  }
  .mobile-menu-icon-light {
      display: none;
  }
}
.coupon-container{
  display: none !important;
}
@media only screen and (max-width: 800px) {
  .logo-container{
    width:35%;
  }
  .coupon-container a{
    margin-right:0px;
  }
}
