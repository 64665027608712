@import "./../../styles/variables.scss";

.footer{
  padding-top:0px;
  background-color: $primaryColor;
  border-top: 10px solid $primaryDarkColor;
}
.footer-inner{
  padding-bottom:180px;
  padding-top: 0;
  img{
    margin-bottom:30px;
  }
  .copyright-text{
    margin-top: 20px;
  }
  .powered-by-stamp{
  	width:100%;
  	text-align: center;
  	img{
  		width:50%;
  		max-width:200px;
      margin-bottom: -10px;
  	}
  }
}
@media only screen and (max-width: 800px) {
  .copyright-text{
    font-size: 12px;
  }
  .footer-inner{
    padding-bottom:150px;
  }
}
