/* Hero Styling */
.hero {
		height: 100vh;
    overflow: hidden;
    display: flex;
    align-items: center;
    text-align: center;
    position: relative;
}
.hero.full {
    height: 100vh;
}
.hero.half {
    height: 50vh;
}
.hero.quarter {
    height: 25vh;
}
.hero.threequarter {
    height: 75vh;
}
.hero-background {
    position: absolute;
    z-index: 1;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    transition: all .75s ease-out;
    transform: scale(1.15, 1.15);
    opacity: 0;
    background-size: cover;
	background-position: center;
}
.ready .hero-background {
    transform: scale(1, 1);
    opacity: 1;
}
.hero-content {
    margin: auto;
    z-index: 3;
    width: 100%;
    max-width: 1400px;
		padding: 20px;
}
.hero-title {
    width: 100%;
    transition: all .5s ease-out;
    transition-delay: .2s;
    transform: translateY(40%);
    opacity: 0;
    color: rgba(255, 255, 255, .75);
    font-size: 120px;
    margin: 0;
}
.hero-title.fancy-font{
	transform: translateY(40%) rotate(-5deg);
}
.ready .hero-title.fancy-font{
	 transform: rotate(-5deg)
}
.hero-subtitle {
    width: 100%;
    transition: all .5s ease-out;
    transition-delay: .15s;
    transform: translateY(80%);
    opacity: 0;
    color: #fff;
	margin-bottom: 0px;
}
.ready .hero-title,
.ready .hero-subtitle {
    transform: translateY(0%);
    opacity: 1;
}
.powered-by-stamp{
	width:100%;
	text-align: center;
	img{
		width:50%;
		max-width:300px;
	}
}
@keyframes heroShrink {
    0% {} 100% {
        transform: scale(1, 1);
        opacity: 1;
    }
}
@keyframes fadeInUp {
    0% {
        transform: translateY(100%);
        opacity: 0;
    }
    100% {
        transform: translateY(0%);
        opacity: 1;
    }
}

@media only screen and (max-width: 800px) {
  .hero-title {
      font-size: 50px;
  }
}
