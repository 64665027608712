$primaryFont: "Helvetica Neue", Helvetica, Arial, sans-serif;
$secondaryFont:"Inter", sans-serif;
$primaryColor: #337F97;
$primaryDarkColor: #206074;
$secondaryColor: #F6B802;
$tertiaryColor: #F35B05;
$darkGrey: #222;
$mediumGrey: #999;
$lightGrey: #f9f9f9;
$black: #000;
$white: #fff;
$green: #4cd964;
$red: #ff2d55;
$globalRadius: 4px;
