.knockout-comp{
  background-size: cover;
  padding-top: 10%;
  padding-bottom: 10%;
  position: relative;
  text-align: center;
  overflow-x:hidden;
  width: 100vw;
  h2{
    font-size: 60px;
    margin-bottom: 20px
  }
  .stamp-text{
    font-size: 90px;
    margin-top: 5%;
    margin-bottom: 5%;
    color: #206074;
    transform: rotate(-5deg);
  }
}
.knockout-layer-container{
  position: relative;
}
.knockout-text{
  width: 100%;
  position: relative;
  z-index: 1;
  background-clip: text;
  color: transparent;
  font-size: 600px;
  font-weight: bold;
  text-shadow: 10px 0px 180px rgba(0,0,0,.35);
  text-transform: uppercase;
  background-size: cover;
  background-position: center;
}

.knockout-text-overlay{
  position: absolute;
  z-index: 2;
  width:60%;
  top: -5%;
  left: 20%;
}
.knockout-content-container{
  padding-top: 5%;
}
@media only screen and (max-width: 1500px) {
  .knockout-comp{
    .knockout-text-overlay{
      position: absolute;
      z-index: 2;
      width:80%;
      top: 5%;
      left: 20%;
    }
  }
}
@media only screen and (max-width: 1600px) {
  .knockout-comp{
    .knockout-text{
      font-size: 400px;
    }
    .knockout-text-overlay{
      width:60%;
      top: 5%;
      left: 20%;
    }
  }
}
@media only screen and (max-width: 1280px) {
  .knockout-comp{
    .knockout-text{
      font-size: 350px;
    }
    .knockout-text-overlay{
      width:90%;
      top: 5%;
      left: 5%;
    }
  }
}
@media only screen and (max-width: 800px) {
  .knockout-comp{
    margin-top: -30%;
    padding-top: 30%;

    .knockout-text{
      font-size: 120px;
    }
    .knockout-text-overlay{
      width:70%;
      top: -5%;
      left:13%;
    }
    h2{
      font-size: 30px;
    }
    .fancy-font{
      font-size: 40px;
    }
  }
}
