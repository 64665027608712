/* Hero Styling */
.full-width-portfolio-teaser-container {
    height: 100vh;
    display: flex;
    align-items: center;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
.full-width-portfolio-teaser-container.right{
  justify-content: flex-end;
}
.full-width-portfolio-teaser-container.left{
  justify-content: flex-start;
}
.full-width-portfolio-teaser-card-container{
  max-width: 450px;
  margin-left: 5%;
  margin-right: 5%;
  width: 100%;
}
.full-width-portfolio-teaser-card {
    padding: 40px;
    padding-top: 80px;
    padding-bottom: 80px;
    background-color: #fff;
    box-shadow: 0px 0px 40px rgba(0,0,0,.15);
    position: relative;
}
.full-width-portfolio-teaser-logo{
  height:60px;
  width:60px;
  border-radius: 50%;
  margin-bottom: 30px;
}
.full-width-portfolio-teaser-title{
  margin-top: 30px;
  margin-bottom: 30px;
  font-size: 14px;
}
.full-width-portfolio-teaser-description{
  margin-top: 0px;
}
.full-width-portfolio-teaser-button{
  margin-top: 30px;
  background-position: center;
  background-size: 40px;
  background-repeat: no-repeat;
  height: 60px;
  width: 40px;
  transition: all .15s ease-in-out;
}
.full-width-portfolio-teaser-button:hover{
  margin-top: 30px;
  height: 60px;
  width: 90px;
}
@media only screen and (max-width: 800px) {

}
