.side-by-side-card-and-image-container{
  position: relative;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  overflow-x:hidden;
}
.side-by-side-card-and-image-inner{
  padding-top: 20%;
  padding-bottom: 20%;
}
.side-by-side-card-and-image-image{
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 1;
  position: relative;
  transform: scale(1.2,1.2);
}
.side-by-side-card-and-image-container .card{
  padding:10%;
  z-index: 2;
  position: relative;
}
.side-by-side-card-and-image-container .card-title{
  font-size: 40px;
  font-weight: bold;
  transform: translateY(-50%) scale(1.5,1.5) translateX(15%);

}
.side-by-side-card-and-image-container .card-description{
  font-size: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
  color:#fff;
}

@media only screen and (max-width: 800px) {
  .side-by-side-card-and-image-container {
    .card-title{
      font-size: 30px;
      font-weight: bold;
      transform: translateY(0) scale(1,1) translateX(0%);
    }
    .side-by-side-card-and-image-image{
      transform: scale(1,1);
    }
  }
}
