.epic-card-grid-container{
  background-size: cover;
  position: relative;
  overflow-x: hidden;
  width:100vw;
}
.epic-card-grid-foreground{
  z-index: 2;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.epic-card-grid-background{
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  bottom:0;
  position: absolute;
  display: flex;
  flex-wrap: wrap;
}
.epic-card-grid-background .parallax-image-1{
  height: 50vh;
  width: 30vw;
  margin-top: 5vh;
  margin-left:2vw;
  position:relative;
  z-index: 3;
  background-size: cover;
  background-position: center;
}
.epic-card-grid-background .parallax-image-2{
  height: 30vh;
  width: 40vw;
  margin-right: 5vw;
  margin-top:10vh;
  position:relative;
  z-index: 3;
  background-size: cover;
  background-position: center;
}
.epic-card-grid-background .parallax-image-3{
  height: 40vh;
  width: 30vw;
  margin-left: 10vw;
  margin-top:-2vh;
  position:relative;
  z-index: 2;
  background-size: cover;
}
.epic-card-grid-background .parallax-image-4{
  height: 50vh;
  width: 30vw;
  margin-left: 2vw;
  margin-top:-10vh;
  position:relative;
  background-size: cover;
  background-position: center;
  z-index: 2;
}
.epic-card-grid-container .card{
  padding:3%;
  z-index: 2;
  position: relative;
  max-width: 600px;
}
.epic-card-grid-container .card-title{
  font-size: 40px;
  font-weight: bold;
  transform: translateY(-50%) scale(1.5,1.5) translateX(15%);
  width: 800px;
}
.epic-card-grid-container .card-description{
  font-size: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
  color:#fff;
}

@media only screen and (max-width: 800px) {
  .epic-card-grid-container .card-title{
    font-size:30px;
    width:auto;
    transform: translateY(0%) scale(1,1) translateX(0%);
  }
  .epic-card-grid-container .card {
    padding: 5%;
    width: 90%;
  }
  .epic-card-grid-background .parallax-image-1{
    margin-left: 0vw;
    margin-top:0vh;
    width: 40vw;
  }
  .epic-card-grid-background .parallax-image-2{
    margin-right: 0;
    margin-top:0vh;
    width: 100vw;
  }
  .epic-card-grid-background .parallax-image-3{
    margin-bottom: 0;
    width: 100vw;
  }
}
