/* Grid */
.row {
    box-sizing: border-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex: 0 1 auto;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}
@media only screen {
    /* Grid */

    .small-1 {
        -ms-flex-preferred-size: 8.333%;
        -webkit-flex-basis: 8.333%;
        flex-basis: 8.333%;
        max-width: 8.333%;
    }
    .small-2 {
        -ms-flex-preferred-size: 16.666%;
        -webkit-flex-basis: 16.666%;
        flex-basis: 16.666%;
        max-width: 16.666%;
    }
    .small-3 {
        -ms-flex-preferred-size: 25%;
        -webkit-flex-basis: 25%;
        flex-basis: 25%;
        max-width: 25%;
    }
    .small-4 {
        -ms-flex-preferred-size: 33.333%;
        -webkit-flex-basis: 33.333%;
        flex-basis: 33.333%;
        max-width: 33.333%;
    }
    .small-5 {
        -ms-flex-preferred-size: 41.666%;
        -webkit-flex-basis: 41.666%;
        flex-basis: 41.666%;
        max-width: 41.666%;
    }
    .small-6 {
        -ms-flex-preferred-size: 50%;
        -webkit-flex-basis: 50%;
        flex-basis: 50%;
        max-width: 50%;
    }
    .small-7 {
        -ms-flex-preferred-size: 58.333%;
        -webkit-flex-basis: 58.333%;
        flex-basis: 58.333%;
        max-width: 58.333%;
    }
    .small-8 {
        -ms-flex-preferred-size: 66.666%;
        -webkit-flex-basis: 66.666%;
        flex-basis: 66.666%;
        max-width: 66.666%;
    }
    .small-9 {
        -ms-flex-preferred-size: 75%;
        -webkit-flex-basis: 75%;
        flex-basis: 75%;
        max-width: 75%;
    }
    .small-10 {
        -ms-flex-preferred-size: 83.333%;
        -webkit-flex-basis: 83.333%;
        flex-basis: 83.333%;
        max-width: 83.333%;
    }
    .small-11 {
        -ms-flex-preferred-size: 91.666%;
        -webkit-flex-basis: 91.666%;
        flex-basis: 91.666%;
        max-width: 91.666%;
    }
    .small-12 {
        -ms-flex-preferred-size: 100%;
        -webkit-flex-basis: 100%;
        flex-basis: 100%;
        max-width: 100%;
    }
}
@media only screen and (min-width: 800px) {
    .medium-1 {
        -ms-flex-preferred-size: 8.333%;
        -webkit-flex-basis: 8.333%;
        flex-basis: 8.333%;
        max-width: 8.333%;
    }
    .medium-2 {
        -ms-flex-preferred-size: 16.666%;
        -webkit-flex-basis: 16.666%;
        flex-basis: 16.666%;
        max-width: 16.666%;
    }
    .medium-3 {
        -ms-flex-preferred-size: 25%;
        -webkit-flex-basis: 25%;
        flex-basis: 25%;
        max-width: 25%;
    }
    .medium-4 {
        -ms-flex-preferred-size: 33.333%;
        -webkit-flex-basis: 33.333%;
        flex-basis: 33.333%;
        max-width: 33.333%;
    }
    .medium-5 {
        -ms-flex-preferred-size: 41.666%;
        -webkit-flex-basis: 41.666%;
        flex-basis: 41.666%;
        max-width: 41.666%;
    }
    .medium-6 {
        -ms-flex-preferred-size: 50%;
        -webkit-flex-basis: 50%;
        flex-basis: 50%;
        max-width: 50%;
    }
    .medium-7 {
        -ms-flex-preferred-size: 58.333%;
        -webkit-flex-basis: 58.333%;
        flex-basis: 58.333%;
        max-width: 58.333%;
    }
    .medium-8 {
        -ms-flex-preferred-size: 66.666%;
        -webkit-flex-basis: 66.666%;
        flex-basis: 66.666%;
        max-width: 66.666%;
    }
    .medium-9 {
        -ms-flex-preferred-size: 75%;
        -webkit-flex-basis: 75%;
        flex-basis: 75%;
        max-width: 75%;
    }
    .medium-10 {
        -ms-flex-preferred-size: 83.333%;
        -webkit-flex-basis: 83.333%;
        flex-basis: 83.333%;
        max-width: 83.333%;
    }
    .medium-11 {
        -ms-flex-preferred-size: 91.666%;
        -webkit-flex-basis: 91.666%;
        flex-basis: 91.666%;
        max-width: 91.666%;
    }
    .medium-12 {
        -ms-flex-preferred-size: 100%;
        -webkit-flex-basis: 100%;
        flex-basis: 100%;
        max-width: 100%;
    }
}
@media only screen and (min-width: 1250px) {
    .large-1 {
        -ms-flex-preferred-size: 8.333%;
        -webkit-flex-basis: 8.333%;
        flex-basis: 8.333%;
        max-width: 8.333%;
    }
    .large-2 {
        -ms-flex-preferred-size: 16.666%;
        -webkit-flex-basis: 16.666%;
        flex-basis: 16.666%;
        max-width: 16.666%;
    }
    .large-3 {
        -ms-flex-preferred-size: 25%;
        -webkit-flex-basis: 25%;
        flex-basis: 25%;
        max-width: 25%;
    }
    .large-4 {
        -ms-flex-preferred-size: 33.333%;
        -webkit-flex-basis: 33.333%;
        flex-basis: 33.333%;
        max-width: 33.333%;
    }
    .large-5 {
        -ms-flex-preferred-size: 41.666%;
        -webkit-flex-basis: 41.666%;
        flex-basis: 41.666%;
        max-width: 41.666%;
    }
    .large-6 {
        -ms-flex-preferred-size: 50%;
        -webkit-flex-basis: 50%;
        flex-basis: 50%;
        max-width: 50%;
    }
    .large-7 {
        -ms-flex-preferred-size: 58.333%;
        -webkit-flex-basis: 58.333%;
        flex-basis: 58.333%;
        max-width: 58.333%;
    }
    .large-8 {
        -ms-flex-preferred-size: 66.666%;
        -webkit-flex-basis: 66.666%;
        flex-basis: 66.666%;
        max-width: 66.666%;
    }
    .large-9 {
        -ms-flex-preferred-size: 75%;
        -webkit-flex-basis: 75%;
        flex-basis: 75%;
        max-width: 75%;
    }
    .large-10 {
        -ms-flex-preferred-size: 83.333%;
        -webkit-flex-basis: 83.333%;
        flex-basis: 83.333%;
        max-width: 83.333%;
    }
    .large-11 {
        -ms-flex-preferred-size: 91.666%;
        -webkit-flex-basis: 91.666%;
        flex-basis: 91.666%;
        max-width: 91.666%;
    }
    .large-12 {
        -ms-flex-preferred-size: 100%;
        -webkit-flex-basis: 100%;
        flex-basis: 100%;
        max-width: 100%;
    }
}
