.video-hero-container{
	opacity: 0;
	transition: all 1s ease-out;
}
.video-hero-container.ready{
	opacity: 1;
}
.video-hero{
	.hero-content {
		border:4px solid #fff;
		margin: auto;
		z-index: 3;
		width: 70%;
		height: 70%;
		max-width: none !important;
		text-align:left;
		position: relative;
	}
	.hero-title {
      font-size: 100px;
			text-shadow: 0px 0px 30px rgba(0,0,0,.25)
  }
	.powered-by-stamp{
		position: absolute;
		bottom: 0;
		left:0;
		width: 100%;
		padding:5;
		text-align: center;
		img{
			width:200px;
		}
	}
}
@media screen and (max-width: 1600px){
	.video-hero{
		.hero-content {
			width: 80%;
			margin-top: 170px;
		}
		.hero-title {
	      font-size: 60px;
	  }
		.vertical-tag{
			margin-top: -100px;
		}
	}
}
@media screen and (max-width: 1280px){
	.video-hero{
		.hero-content {
			width: 90%;
			height: 70%;
			margin-top: 100px;
		}
		.hero-title {
	      font-size: 60px;
	  }
	}
}
@media only screen and (max-width: 800px) {
	.video-hero-container{
		height: 70vh !important;
		min-height: 70vh !important;
	}
	#widget2,.Player__videoIframe___3_jFK,.Player__videoInnerContainer___3idnr,.Player__videoContainer___2TVqS{
		height:70vh !important;
	}
  .video-hero{
		.hero-title {
	      font-size: 40px;
	  }
	}
}
