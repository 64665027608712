/* Visibility */
@media screen{
	.show-for-small{
		display:none;
	}
	.hide-for-small{
		display:block;
	}
}
@media only screen and (max-width: 800px) {
    .small-centered-text {
        text-align: center;
    }
    .small-right-text {
        text-align: right;
    }
    .small-left-text {
        text-align: right;
    }
	.show-for-small{
		display:block;
	}
	.hide-for-small{
		display:none;
	}
}

@media screen and (min-width: 800px) and (max-width: 1280px) {

}
@media screen and (min-width: 1280px){

}
