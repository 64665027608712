/* BangerText */
@import "./../../styles/variables.scss";
.parallax-collage-1{
  background-size: cover;
  background-position: center;
  position: relative;
}
.parallax-collage-1-background{
	background-size:cover;
	position: relative;
	text-align: center;
	padding-top: 5%;
}
.parallax-collage-1-image-1{
	width:80%;
	margin: auto;
  height: 100%;
  background-size: cover;
  background-position: center;
}
.parallax-collage-1-image-2{
	width:80%;
	left: -10%;
	bottom:10vh;
	position: absolute;
  height: 35vh;
  background-size: cover;
  background-position: center;
}
.parallax-collage-1-content{
	width:100%;
	margin: auto;
	margin-top: 40%;
	position: relative;
	z-index: 5;
	background-color: $primaryColor;
  color:$white;
	padding:40px;
	margin-left: -15%;
	padding-top: 60px;
	padding-bottom: 60px;
	box-shadow:0px 0px 60px rgba(0,0,0,.1);

}
.parallax-collage-1-content h1{
	font-size: 60px;
	margin-top: 0px;
}
.parallax-collage-1-content p{
	font-size: 20px;
	line-height: 1.75;
}
.parallax-collage-1{
	background-color: $lightGrey;
}
.parallax-collage-1-content .cta{
	display: inline-block;
    padding: 40px;
    padding-bottom: 20px;
    padding-top: 20px;
    text-decoration: none;
    font-weight: bold;
	border: 1px solid $black;
    background-color: $white;
    color: $black;
    margin-top: 20px;
	transition: all .25s ease-out;
}
.parallax-collage-1-content .cta:hover{
    background-color: $black;
    color: $white;
}
.parallax-collage-description-container{
	padding-left:5%;
	padding-right:5%;
}
@media only screen and (min-width: 1440px) {
	.parallax-collage-1-content h1{
		font-size: 60px;
	}
}
@media only screen and (max-width: 1440px) {
	.parallax-collage-1-content h1{
		font-size: 50px;
	}
}
@media only screen and (max-width: 1280px) {
  .parallax-collage-1-content{
    padding:10px;
  }
  .parallax-collage-1{
    padding-left:0;
    padding-right: 0;
  }
  .parallax-collage-1-image-2{
  	bottom:-5vh;
  }
}
@media only screen and (max-width: 800px) {

  .parallax-collage-1-content{
    margin-left: 10px;
    margin-right: 10px;
    width: auto;
    padding-bottom: 5%;
  }
  .photo-grid-collage .medium-7{
		margin-bottom: -50%;
	}
  .parallax-collage-1-content h1{
		font-size: 40px;
    margin-top: 30px;
    padding-left: 5%;
	}
  .parallax-collage-1 .lip{
    margin-top: 0px;
  }
}
