@import "variables.scss";

input, textarea{
  outline: none;
}
input[type=text], textarea{
  padding:15px;
  box-shadow: none;
  width: 100%;
  border:0;
  border-bottom:0px solid $primaryColor;
  border-radius: $globalRadius;
  background-color: rgba(255, 255, 255,.25);
  -webkit-transition: all .25s ease-in-out;
  transition: all .25s ease-in-out;
  color:#666;
}
input[type=text]:focus, textarea:focus {
    background-color: rgba(255, 255, 255,.5);
}
input[type=submit]{
  padding:15px;
  box-shadow: none;
  width: 100%;
  border:0;
  border-radius: $globalRadius;
}

@media screen and (max-width: 800px) {
	input[type=text], textarea{
	  background-color: rgba(255, 255, 255,.85);
	}
	input[type=text]:focus, textarea:focus {
		background-color: rgba(255, 255, 255,1);
	}

}

@media screen and (min-width: 800px) and (max-width: 1280px) {

}
@media screen and (min-width: 1280px){

}
