@import "variables.scss";

$paddingMultiplier:1;

.full-width {
    width: 100%;
}
.half-width {
    width: 50%;
}
.quarter-width {
    width: 25%;
}
.max-width-container{
  margin:auto;
  width: 100%;
  max-width: 100px;
}
.medium-max-width-container{
  margin:auto;
  width: 100%;
  max-width: 1200px;
}
.small-max-width-container{
  margin:auto;
  width: 100%;
  max-width: 800px;
}
.centered-text{
	text-align:center;
}
.center-position{
  margin:auto;
}
@media screen and (max-width: 800px) {
  .large-padding{
    padding:$paddingMultiplier * 20px
  }
  .medium-padding{
    padding:$paddingMultiplier * 15px
  }
  .small-padding{
    padding:$paddingMultiplier * 5px
  }
  .tiny-padding{
    padding:$paddingMultiplier * 2.5px
  }
}

@media screen and (min-width: 800px) and (max-width: 1280px) {
  .large-padding{
    padding:$paddingMultiplier * 55px
  }
  .medium-padding{
    padding:$paddingMultiplier * 37.5px
  }
  .small-padding{
    padding:$paddingMultiplier * 15px
  }
  .tiny-padding{
    padding:$paddingMultiplier * 7.5px
  }
}
@media screen and (min-width: 1280px){
  .large-padding{
    padding:$paddingMultiplier * 80px
  }
  .medium-padding{
    padding:$paddingMultiplier * 50px
  }
  .small-padding{
    padding:$paddingMultiplier * 20px
  }
  .tiny-padding{
    padding:$paddingMultiplier * 10px
  }
}
