.sticky-countdown-dock.active{
  transform: translateY(0);
}
.sticky-countdown-container{
  position: fixed;
  width:100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 10px;
  padding-bottom: 0px;
  text-align: center;
  bottom: 0;

  .bg{
    bottom: 0px;
    position: absolute;
    z-index: 1;
    width: 300px;
    transform: scale(1.2,.85);
    }
  .mountain-shadow{
    box-shadow: 0px -100px 100px rgba(0, 0, 0, 1);
    width: 350px;
    height: 38px;
    border-radius: 100px;
  }
  .mountain, .mountain-two, .mountain-three {
      position: absolute;
      bottom: 0;
      border-left: 150px solid transparent;
      border-right: 150px solid transparent;
      border-bottom: 180px solid #206074;
      z-index: 1;

  }
  .mountain-two {
      left: 100px;
      bottom: -20px;
      z-index: 0;
  }
  .mountain-three {
      left: -80px;
      bottom:-30px;
      z-index: 0;
  }
  .mountain-top {
      position: absolute;
      right: -65px;
      border-left: 65px solid transparent;
      border-right: 65px solid transparent;
      border-bottom: 77px solid #ceeaf6;
      z-index: 2;
  }
  .mountain-cap-1, .mountain-cap-2, .mountain-cap-3 {
      position: absolute;
      top: 70px;
      border-left: 25px solid transparent;
      border-right: 25px solid transparent;
      border-top: 25px solid #ceeaf6;
  }
  .mountain-cap-1 { left: -55px; }
  .mountain-cap-2 { left: -25px; }
  .mountain-cap-3 { left: 5px; }

}
.sticky-countdown-dock{
  max-width: 400px;
  width: 100%;
  transform: translateY(200px);
  transition: all .5s ease-in-out;
  border-radius: 4px;
  padding:10px;
  padding-left: 20px;
  padding-right: 20px;
  position: relative;
  z-index: 100;
  .row{
    z-index: 100;
    position: relative;
  }
  .countdown-title{
    font-size: 10px;
  }
  .countdown-large-text{
    font-size: 18px;
    font-weight: bold;
  }
  .countdown-small-text{
    font-size: 14px;
  }
  .countdown-tile{
    padding:1%;
    text-align: center;
  }

}
@media only screen and (max-width: 800px) {
  .sticky-countdown-container{
    transform: scale(.75,.75);
    margin-bottom:-10px;
  }
}
