/* BangerText */
.banger-text{
  padding:20px;
  padding-top: 7.5%;
  padding-bottom: 7.5%;
  font-size: 40px;
  font-weight: bold;
  width:100%;
}
.banger-text-accent{
  height: 7px;
  background-color: #000;
  width: 100px;
  margin-bottom: 2%;
}
.hero-background-tint {
    position: absolute;
    z-index: 2;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    transition: all .75s ease-out;
    transform: scale(1.15, 1.15);
    opacity: 0;
    background-size: cover;
}

@media only screen and (max-width: 3000px) {
    .banger-text{
      font-size: 40px;
    }
}

@media only screen and (max-width: 2000px) {
    .banger-text{
      font-size: 30px;
    }
}

@media only screen and (max-width: 1200px) {
    .banger-text{
      font-size: 22px;
    }
}

@media only screen and (max-width: 800px) {
    .banger-text-accent{
      margin-bottom: 5%;
    }
    .banger-text{
      padding-top: 20%;
      padding-bottom: 20%;
      font-size: 20px;
    }
}
