.countdown-container{
  padding-top: 7%;
  padding-bottom: 7%;
  position: relative;
  text-align: center;
}
.countdown-title{
  font-size: 40px;
  font-weight: bold;
}
.countdown-container{
  .countdown-large-text{
    background: -webkit-linear-gradient(#fff, #337F97);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}
.countdown-large-text{
  font-size: 130px;
  font-weight: bold;

}
.countdown-small-text{
  font-size: 20px;
}
.countdown-tile{
  padding:2%;
  text-align: center;
}
@media only screen and (max-width: 1600px) {
  .countdown-large-text{
    font-size: 120px;
  }
}
@media only screen and (max-width: 1280px) {
  .countdown-large-text{
    font-size: 80px;
  }
}
@media only screen and (max-width: 800px) {
  .countdown-tile{
    padding:1%;
  }
  .countdown-large-text{
    font-size: 50px;
  }
  .countdown-container{
    padding-bottom: 14%;
  }
}
