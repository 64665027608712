/* BangerText */
@import "variables.scss";

.clipped-banger-title{
  font-size: 100px;
  margin-bottom:0px;
  font-family: $secondaryFont;
}
.clipped-banger-text{
  font-family: $primaryFont;
  font-weight: normal;
  padding-left: 20px;
  padding-right:20px;
}
@media only screen and (max-width: 800px) {
   .clipped-banger-title{
    font-size: 35px; 
   }
}
